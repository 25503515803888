.home img {
  max-height: 450px;
  width: 100%;
  object-fit: cover;
  border: 1px solid var(--primary-color)
}

@media screen and (max-width: 767px) {
  .home img {
    height: 300px;
    margin-top: 20px;
    object-position: top center;
  }
}
