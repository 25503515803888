:root {
  --background-color: rgba(245, 245, 245);
  --primary-color: #323232;
  --secondary-color: #909090;
}

html,
body,
#root {
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  color: var(--primary-color);
  line-height: 24px;
  font-family: 'Neuzeitgro lig', sans-serif;
  font-weight: normal;
  margin: 0;
  font-synthesis: none;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

::selection {
  background: #d0d5dc;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: inherit;
}

h2 {
  font-size: 20px;
}

h3 {
  font-weight: 400;
  font-weight: bold;
}

.content {
  flex-grow: 1;
}

.br {
  margin-bottom: 30px;
}

.center {
  text-align: center !important;
}

.container {
  height: auto;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0px;
}

.flexbox {
  display: flex;
}

.grid {
  display: grid;
}

img {
  max-width: 100%;
}

.CookieConsent {
  background: var(--background-color) !important;
  border: 1px solid var(--primary-color);
  max-width: 350px;
  color: var(--primary-color) !important;
  padding: 20px 20px 0 20px;
  display: block !important;
  bottom: 20px !important;
  right: 20px !important;
  left: unset !important;
  font-family: 'Neuzeitgro lig', sans-serif;
}

.CookieConsent div {
  margin: 20px 0 !important;
}

.CookieConsent button {
  background: var(--primary-color) !important;
  color: #fff !important;
  width: 100%;
  margin: 20px 0 0 0 !important;
  padding: 10px 0 !important;
  font-family: 'Neuzeitgro lig', sans-serif;
  font-size: 14px;
}

@media screen and (max-width: 1140px) {
  .container {
    max-width: 80%;
  }
  .CookieConsent {
    max-width: 80% !important;
    margin: auto !important;
    left: 20px !important;
  }
}

@media screen and (max-width: 500px) {
  .container {
    max-width: 90%;
  }
  .CookieConsent {
    max-width: 90% !important;
  }
}
