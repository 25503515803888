.about .grid {
  grid-template-columns: 0.75fr 0.25fr;
  gap: 40px;
  align-items: center;
}

.about img {
  margin: 15px 0;
}

.about a {
  font-weight: bold;
}

.areas {
  margin-top: 60px;
}

.areas .title {
  font-weight: 700;
  margin-top: 30px;
}

.areas h3 {
  font-size: 16px;
}

.areas p span {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .about .grid {
    grid-template-columns: 1fr;
    gap: 0;
    align-items: unset;
  }
  .about img {
    display: none;
  }
}
