.footer {
  text-align: center;
  margin-top: auto;
  background-color: var(--background-color);
  flex-shrink: 0;
  margin: 60px 0 0;
  height: 80px;
}

.language-selector {
  display: flex;
  justify-content: center;
}

.language-selector div {
  padding: 0 5px;
}

.language-selector .lang {
  cursor: pointer;
}

.language-selector .lang.active {
  font-weight: 700;
}

@media screen and (min-width: 767px) {
  .footer {
    padding: 30px 0;
    margin-bottom: 30px;
  }
}