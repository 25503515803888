.header {
  position: sticky;
  top: 0;
  z-index: 10;
  text-align: center;
  background-color: var(--background-color);
}

.navigation {
  position: relative;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 20px 30px 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.navigation-items {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.navigation-item {
  display: block;
  padding: 0 0 0 25px;
}

.navigation-wrap {
  display: flex;
  width: 33%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -10px;
}

.navigation-wrap.right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.navigation-item.active {
  color: var(--secondary-color);
}

.header-headline {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
}

.header-headline div {
  font-weight: normal;
  text-transform: none;
  color: var(--secondary-color);
  line-height: 10px;
  font-size: 14px;
  text-align: left;
}

.menu {
  position: absolute;
  background-color: #fff;
  width: 100vw;
  height: calc(100vh + 1px);
  top: 0;
  left: 0;
  padding: 0px 20px;
  z-index: 98;
  margin-top: -1.5px;
}

.menu-items {
  margin-top: 100px;
}
.menu a:not(.home-link),
.menu .language-selector {
  font-size: 26px;
  margin-bottom: 50px;
}

.menu .dropdown-header {
  font-size: 26px;
  text-align: left;
}

.menu .dropdown-list-container {
  width: 100%;
  left: 0px;
  top: 40px;
}

@media screen and (min-width: 767px) {
  .large {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
  }
  .small {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .large {
    display: none;
  }
  .small {
    display: grid;
    grid-template-columns: 0.2fr 0.6fr 0.2fr;
    padding: 10px 0;
    align-items: start;
  }
  .menu-icon {
    position: absolute;
    top: 15px;
    right: 0;
    cursor: pointer;
  }
  .menu-icon.close {
    top: 24px;
    right: 5%;
  }
  .navigation-item {
    line-height: 30px;
    padding: 0;
  }
  .navigation-items {
    margin-bottom: 0;
  }
  .header-headline {
    margin-top: 0;
  }
  .header-headline div {
    text-align: center;
  }
  .language-selector {
    margin: 30px 0;
  }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
  .menu-icon.close {
    top: 24px;
    right: 10%;
  }
}
