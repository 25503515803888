.contact {
  text-align: center;
  margin-top: 30px;
}

.contact form {
  max-width: 400px;
  margin: 0 auto;
}

.contact .button {
  background: var(--primary-color);
  color: #fff !important;
  cursor: pointer;
  padding: 15px !important;
}

.contact .button,
.contact input,
.contact textarea {
  padding: 10px;
  width: 100%;
  border: none;
  outline: none;
  color: var(--primary-color);
  font-family: inherit;
  appearance: none;
  -webkit-border-radius: 0;
}

.contact input,
.contact textarea {
  background: transparent;
  margin-bottom: 15px;
  border: 1px solid var(--primary-color);
}

.contact input::placeholder,
.contact textarea::placeholder {
  color: var(--primary-color);
}

.contact textarea {
  min-height: 100px;
}

.overlay-success,
.overlay-error {
  height: 205px;
  width: 400px;
  background-color: #fff;
  border: 1px solid var(--primary-color);
  position: absolute;
  top: 50%;
  z-index: 10;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: none;
}

.overlay-success .menu-icon.close,
.overlay-error .menu-icon.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
